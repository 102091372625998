.alphabet-card {
  width: 6rem;
  height: 6rem;
  background: white;
  color: var(--color-navy);
  border: 3px solid var(--color-navy);
  border-radius: 0.5rem;
  display: flex;
  font-size: 2rem;
  cursor: pointer;
  transition: width 0.3s, height 0.3s, top 0.3s, opacity 0.3s;
  padding-left: 0.5rem;
  gap: 0.5rem;
}

.alphabet-card.expanded {
  position: fixed;
  z-index: 1;
  transition: width 0.3s, height 0.3s;
  width: 90%;
  cursor: default;
  padding: 1rem;
  background-size: initial !important;
  background-position: center !important;
  min-height: 10rem;
  gap: 1rem;
  margin: 0;
  background: white;
  color: var(--color-navy);
  border: 3px solid var(--color-navy);
  border-radius: 0.5rem;
  font-size: 3rem;
}

.alphabet-card.expanded .speaker-icon {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.alphabet-card__close {
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(61, 48, 162, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}