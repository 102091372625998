p,
h1 {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

nav {
  padding: 1rem;
  font-size: 1.5rem;
}

hr {
  border-color: #551a8b;
  width: 75%;
  max-width: 900px;
  border-width: 0.2px;
  border-style: dashed;
}

.button {
  border: none;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.page {
  padding: 2rem;
}

.page--home {
  min-height: calc(100dvh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  gap: 2rem;
  font-size: 3rem;
}

.page--alphabet {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}

.page--words {
  display: flex;
  flex-flow: column;
  gap: 2rem;
}

.page--words .alphabet {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.alphabet .button {
  min-width: 3rem;
}

.page--words .words--current-image {
  text-align: center;
  min-height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page--words .words--current-word {
  text-align: center;
  padding: 1rem 0;
}

.page--words .user-correct-letters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 2rem;
}

.page--words .user-correct-letters span {
  border-bottom: 1px solid #ccc;
  min-height: 2rem;
  min-width: 2rem;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  min-width: 75%;
  text-align: center;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  font-size: 2rem;
}

.modal-content__actions {
  display: flex;
  gap: 2rem;
  justify-content: center;
}