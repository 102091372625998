:root {
  --primary-color: #3498db;
  --font-size: 16px;
  --color-navy: rgb(61, 48, 162);
  /* #3D30A2 */
  --color-purple: rgb(177, 94, 255);
  /* #B15EFF */
  --color-orange: rgb(255, 163, 60);
  /* #FFA33C */
  --color-yellow: rgb(255, 251, 115);
  /* #FFFB73 */
}

body {
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
  background-color: white;
}